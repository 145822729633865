
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';
// 拣货状态
import {setConfigCharacters} from "@/utils/common";

export const YMS_STATUS_LIST = [
  {value: 1, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001156')},
  {value: 2, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001157')},
  {value: 3, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001158')},
  {value: 4, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001159')},
  {value: 5, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001160')},
  {value: 6, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001161')},
  {value: 7, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001162')},
  {value: 8, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001163')},
];

// 出库单状态
export const YMS_PACKAGE_INFO_STATUS = [
  {value: 1, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001164')},
  {value: 2, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001165')},
  {value: 3, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001156')},
  {value: 4, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001166')},
  {value: 5, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001167')},
  {value: 6, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001168')},
  {value: 7, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001169')},
  {value: 8, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001163')}
];

export const PICK_STATUS_LIST = [
  {
    value: 1,
    title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001170')
  },
  {
    value: 2,
    title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001144')
  },
  {
    value: 3,
    title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001159')
  },
  {
    value: 4,
    title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001145')
  },
  {
    value: 5,
    title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001171')
  },
  {
    value: 21,
    title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001172')
  },
  {
    value: 99,
    title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001173')
  }
];

export const YMS_PACKAGE_STATUS = [
  {
    value: 0,
    title: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001143')}`
  },
  {
    value: 9,
    title: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001144')}`
  },
  {
    value: 10,
    title: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001145')}`
  },
  {
    value: 11,
    title: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001146')}`
  },
  {
    value: 12,
    title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001174')
  }
];

export const YMS_SUBPACKAGE_STATUS = [
  {value: 3, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001156')},
  {value: 4, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001166')},
  {value: 5, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001175')},
  {value: 6, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001168')},
  {value: 7, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001146')},
  {value: 8, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001163')}
];

// listing inputType
export const LISTING_INPUT_TYPE = [
  {
    value: '1',
    label: 'text'
  }, {
    value: '2',
    label: alias1bddc4d174174d47a6dfb0437dace856.t('key1000457')
  }, {
    value: '3',
    label: alias1bddc4d174174d47a6dfb0437dace856.t('key1001176')
  }, {
    value: '4',
    label: alias1bddc4d174174d47a6dfb0437dace856.t('key1000456')
  }, {
    value: '5',
    label: alias1bddc4d174174d47a6dfb0437dace856.t('key1001177')
  }
];


// 规则是否类型
export const RULE_TYPE_IS = ['order-service-A044', 'order-service-A045', 'order-service-A042', 'order-service-A040', 'order-service-A035', 'order-service-A036', 'order-service-A037', 'order-service-A038', 'order-service-A047', 'order-service-A048', 'order-service-A046',
  'CORE-YCCR-045', 'DISTRIBUTOR-DCCR-A024', 'DISTRIBUTOR-DCCR-A028'
];

// 规则单个input 类型
export const RULE_TYPE_INPUT = ['CORE-YCCR-003', 'CORE-YCCR-004', 'DISTRIBUTOR-DCCR-A005', 'DISTRIBUTOR-DCCR-A004'];

// 规则 两个checkbox input 类型
export const RULE_TYPE_TCI = [
  'CORE-YCCR-014', 'CORE-YCCR-015', 'CORE-YCCR-016', 'CORE-YCCR-017', 'CORE-YCCR-018', 'CORE-YCCR-019', 'CORE-YPDR-008', 'CORE-YPDR-006', 'CORE-YPDR-009', 'CORE-YCCR-010', 'CORE-YCCR-013', 'DISTRIBUTOR-DCCR-A012', 'DISTRIBUTOR-DCCR-A016', 'DISTRIBUTOR-DCCR-A017', 'DISTRIBUTOR-DCCR-A018', 'DISTRIBUTOR-DCCR-A019', 'DISTRIBUTOR-DCCR-A020', 'DISTRIBUTOR-DCCR-A021', 'DISTRIBUTOR-DCCR-A022'];

// 平台订单状态
export const PLATFORM_ORDER_STATUS = [
  {label: alias1bddc4d174174d47a6dfb0437dace856.t('key1000095'), value: 'null'},
  {label: alias1bddc4d174174d47a6dfb0437dace856.t('key1001164'), value: 1},
  {label: alias1bddc4d174174d47a6dfb0437dace856.t('key1001178'), value: 2},
  {label: alias1bddc4d174174d47a6dfb0437dace856.t('key1001179'), value: 3},
  {label: alias1bddc4d174174d47a6dfb0437dace856.t('key1001180'), value: 4},
  {label: alias1bddc4d174174d47a6dfb0437dace856.t('key1001169'), value: 5},
  {label: alias1bddc4d174174d47a6dfb0437dace856.t('key1001163'), value: 6},
];

// 三方平台订单状态
export const THIRH_PARTY_PLATFORM_ORDER_STATUS = [
  {value: 0, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001181')},
  {value: 1, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001182')},
  {value: 2, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001169')},
  {value: 21, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001183')},
  {value: 3, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001032')},
  {value: 31, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001184')},
  {value: 4, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001163')},
  {value: 41, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001185')}
];

// 供应商--出库单类型
export const OUTBOUNDTYPELIST = [ // 1:销售出库 2:云卖中心仓备货 3:三方仓备货 null:全部
  {value: 0, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000095')},
  {value: 1, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001186')},
  {value: 2, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001187')},
  {value: 3, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001188')},
  {value: 4, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001189')},
];

// 云卖订单状态
export const CLOUDSALESORDERSTATUS = [
  {selected: true, value: 0, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000095')},
  {selected: false, value: 1, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001164')},
  {selected: false, value: 2, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001165')},
  {selected: false, value: 3, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001156')},
  {selected: false, value: 4, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001166')},
  {selected: false, value: 5, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001167')},
  {selected: false, value: 6, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001168')},
  {selected: false, value: 7, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001169')},
  {selected: false, value: 8, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001163')}
];

// 销售状态
export const SALESSTATUS = [
  {selected: true, value: null, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000095')},
  {selected: false, value: 1, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000401')},
  {selected: false, value: 2, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000344')},
  {selected: false, value: 0, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000400')},
];

// 审核状态
export const AUDITSTATUS = [
  {selected: true, value: 'null', title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000095')},
  {selected: false, value: 0, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001190')},
  {selected: false, value: 1, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001191')},
  {selected: false, value: 2, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001192')},
  {selected: false, value: 3, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000654')}
];

// 费用类型
export const EXPENSETYPE = [
  {selected: true, value: null, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000095')},
  {selected: false, value: 1, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001193')},
  {selected: false, value: 2, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001194')},
  {selected: false, value: 3, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001195')},
  {selected: false, value: 4, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001196')},
  {selected: false, value: 5, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001197')},
  {selected: false, value: 6, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001198')},
  {selected: false, value: 7, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001199')},
  {selected: false, value: 8, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001200')}
];

// 启用停用状态
export const ENABLEDISABLESTATUS = [
  {selected: true, value: null, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000095')},
  {selected: false, value: 1, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000499')},
  {selected: false, value: 0, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000500')}
];

//面单类型
export const EACESHEETTYPE = [
  {selected: true, value: null, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000095')},
  {selected: false, value: 'LOGIS', title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000108')},
  {selected: false, value: 'YMS', title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000109')},
  {selected: false, value: 'NONE', title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000110')},
  {selected: false, value: 'MIX', title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000111')}
];

// 异常状态
export const ABNORMALSTATE = [
  {selected: true, value: 0, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000095')},
  {selected: false, value: 1, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001201')},
  {selected: false, value: 2, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000341')}
];

// 商品状态
export const COMMODITYSTATUS = [
  {selected: true, value: null, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000095')},
  {selected: false, value: 0, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000344')},
  {selected: false, value: 1, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000401')},
  {selected: false, value: 2, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000800')},
  {selected: false, value: 3, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000801')}
];

// 费用流水状态
export const EXPENSEFLOWSTATUS = [
  {selected: true, value: null, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000095')},
  {selected: false, value: 1, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001202')},
  {selected: false, value: 0, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001203')}
];

// 注册来源
export const REGISTER_SOURCE = [
  {value: 0, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000095')},
  {value: 1, title: 'TONGTOOL'},
  {value: 3, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001204')},
  {value: 4, title: 'Shopify'},
  {value: 5, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001205')},
  {value: 6, title: 'H5'}
];

// 结算状态(供应商系统) 1 待审核状态 2 待确认 3 等待收款/付款 4 已收款/已付款 5 作废
export const SUPPLIER_SETTLEMENTSTATUS = [
  {selected: true, value: 2, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001206')},
  {selected: false, value: 3, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001207')},
  {selected: false, value: 4, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001208')}
];
// 结算状态(运营系统)
export const YMS_SETTLEMENTSTATUS = [
  {selected: false, value: 'null', title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000095')},
  {selected: false, value: 1, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001191')},
  {selected: true, value: 2, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001209')},
  {selected: false, value: 3, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001210')},
  {selected: false, value: 4, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001211')}
];
// 语种对应
export const mappingLang = {
  EN: 'usName',  // 英文
  FRA: 'frName', // 法文
  GER: 'deName', // 德文
  SPN: 'esName', //西班牙
  NL: 'nlName',  // 荷兰语
  PLN: 'plName', // 波兰
  PT: 'ptName',  // 葡萄牙
  IT: 'itName',  // 意大利
  CN: 'cnName'   // 中文
};

const fixPath = 'https://www.yms.com/yms-distributor-service/filenode/s'; // 固定写死
export const videos = {
  1: fixPath + alias1bddc4d174174d47a6dfb0437dace856.t('key1001212'),
  2: fixPath + alias1bddc4d174174d47a6dfb0437dace856.t('key1001213'),
  3: fixPath + alias1bddc4d174174d47a6dfb0437dace856.t('key1001214'),
  4: fixPath + alias1bddc4d174174d47a6dfb0437dace856.t('key1001215'),
  5: fixPath + alias1bddc4d174174d47a6dfb0437dace856.t('key1001216'),
  6: fixPath + alias1bddc4d174174d47a6dfb0437dace856.t('key1001217'),
};
export const DELIVERYORDERTYPE = [
  // {value: 1, title: '销售出库'},
  {value: 2, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001187')},
  {value: 3, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001188')},
  {value: 4, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001189')}
];

// 多语种名称
export const MULTILINGUALNAMESLIST = {
  CN: alias1bddc4d174174d47a6dfb0437dace856.t('key1001218'),
  US: alias1bddc4d174174d47a6dfb0437dace856.t('key1001219'),
// {type: 'frName', name: '法语'},
// {type: 'deName', name: '德语'},
// {type: 'esName', name: '西班牙语'},
// {type: 'nlName', name: '荷兰语'},
// {type: 'plName', name: '波兰语'},
// {type: 'ptName', name: '葡萄牙语'},
// {type: 'itName', name: '意大利语'}
}

// 消息模板
export const INFORMATIONTYPELIST = [
  {
    value: 1,
    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1000341'),
    name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001220'),
    triggerConditionTxt: alias1bddc4d174174d47a6dfb0437dace856.t('key1001221'),
    dynamicParamList: [alias1bddc4d174174d47a6dfb0437dace856.t('key1001222')], // 动态参数
    messageTemplate: alias1bddc4d174174d47a6dfb0437dace856.t('key1001223')
  },
  {
    value: 2,
    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1001224'),
    name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001225'),
    dynamicParamList: ['X'], // 动态参数
    messageTemplate: alias1bddc4d174174d47a6dfb0437dace856.t('key1001226')
  },
  {
    value: 3,
    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1001227'),
    name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001228'),
    dynamicParamList: ['X'],
    messageTemplate: alias1bddc4d174174d47a6dfb0437dace856.t('key1001229'),
  },
  {
    value: 4,
    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1001230'),
    name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001231'),
    dynamicParamList: ['X'],
    messageTemplate: alias1bddc4d174174d47a6dfb0437dace856.t('key1001232')
  },
  {
    value: 5,
    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1001233'),
    name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001234'),
    dynamicParamList: ['X'],
    messageTemplate: alias1bddc4d174174d47a6dfb0437dace856.t('key1001235')
  },
  {
    value: 6,
    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1001236'),
    name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001237'),
    dynamicParamList: ['X'],
    messageTemplate: alias1bddc4d174174d47a6dfb0437dace856.t('key1001238')
  },
  {
    value: 7,
    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1000097'),
    name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001239'),
    triggerConditionTxt: alias1bddc4d174174d47a6dfb0437dace856.t('key1001240'),
    dynamicParamList: [alias1bddc4d174174d47a6dfb0437dace856.t('key1001222')],
    messageTemplate: alias1bddc4d174174d47a6dfb0437dace856.t('key1001241')
  },
  {
    value: 8,
    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1000344'),
    name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001242'),
    triggerConditionTxt: alias1bddc4d174174d47a6dfb0437dace856.t('key1001243'),
    dynamicParamList: [alias1bddc4d174174d47a6dfb0437dace856.t('key1001244'), 'SPU/SKU'],
    messageTemplate: alias1bddc4d174174d47a6dfb0437dace856.t('key1001245')
  },
  {
    value: 9,
    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1001246'),
    name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001247'),
    title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001248'),
    triggerConditionTxt: alias1bddc4d174174d47a6dfb0437dace856.t('key1001246'),
    dynamicParamList: ['SPU'],
    messageTemplate: alias1bddc4d174174d47a6dfb0437dace856.t('key1001249')
  },
  {
    value: 10,
    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1001250'),
    name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001251'),
    title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001252'),
    triggerConditionTxt: alias1bddc4d174174d47a6dfb0437dace856.t('key1001250'),
    dynamicParamList: ['SPU'],
    messageTemplate: alias1bddc4d174174d47a6dfb0437dace856.t('key1001253')
  },
  {
    value: 11,
    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1001254'),  // 触发条件的文案
    name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001255'), // 消息类型名称
    title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001256'), // 消息的标题
    triggerConditionTxt: alias1bddc4d174174d47a6dfb0437dace856.t('key1001254'),
    dynamicParamList: [alias1bddc4d174174d47a6dfb0437dace856.t('key1000788')],
    messageTemplate: alias1bddc4d174174d47a6dfb0437dace856.t('key1001257')
  },
  {
    value: 12,
    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1001258'),  // 触发条件的文案
    name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001259'), // 消息类型名称
    title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001259'), // 消息的标题
    triggerConditionTxt: alias1bddc4d174174d47a6dfb0437dace856.t('key1001258'),
    dynamicParamList: [alias1bddc4d174174d47a6dfb0437dace856.t('key1001260')],
    messageTemplate: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001147')}<span class="highlight_color">${alias1bddc4d174174d47a6dfb0437dace856.t('key1001148')}</span>${alias1bddc4d174174d47a6dfb0437dace856.t('key1001149')}${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001150')}`
  },
  {
    value: 13,
    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1001261'),
    name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001262'),
    triggerConditionTxt: alias1bddc4d174174d47a6dfb0437dace856.t('key1001261'),
    dynamicParamList: ['SPU'],
    messageTemplate: alias1bddc4d174174d47a6dfb0437dace856.t('key1001263')
  },
  {
    value: 14,
    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1001264'),
    name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001265'),
    triggerConditionTxt: alias1bddc4d174174d47a6dfb0437dace856.t('key1001264'),
    dynamicParamList: ['SPU'],
    messageTemplate: alias1bddc4d174174d47a6dfb0437dace856.t('key1001266')
  },
  {
    value: 15,
    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1001267'),
    name: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001151')}`,
    triggerConditionTxt: alias1bddc4d174174d47a6dfb0437dace856.t('key1001267'),
    dynamicParamList: [`${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1000350')}`],
    messageTemplate: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001152')}<span class="highlight_color">【${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001153')}</span>${alias1bddc4d174174d47a6dfb0437dace856.t('key1001154')}`
  },
  {
    value: 16,
    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1001268'),  // 触发条件的文案
    name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001269'), // 消息类型名称
    title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001270'), // 消息的标题
    triggerConditionTxt: alias1bddc4d174174d47a6dfb0437dace856.t('key1001268'),
    dynamicParamList: [alias1bddc4d174174d47a6dfb0437dace856.t('key1001271'), alias1bddc4d174174d47a6dfb0437dace856.t('key1000350')],
    messageTemplate: alias1bddc4d174174d47a6dfb0437dace856.t('key1001272')
  },
  {
    value: 17,
    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1001273'),
    name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001274'),
    title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001274'), // 消息的标题
    triggerConditionTxt: alias1bddc4d174174d47a6dfb0437dace856.t('key1001273'),
    dynamicParamList: ['SPU'],
    messageTemplate: alias1bddc4d174174d47a6dfb0437dace856.t('key1001275')
  },
];

// 供应商--订单列表--出库单状态
export const SUPPLIEROUTPACKAGEORDERSTATUS = [
  {value: 1, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001156')},
  {value: 2, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001160')},
  {value: 3, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001276')},
  {value: 4, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001146')},
  {value: 5, title: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001155')}`},
  {value: 6, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001277')},
  {value: 7, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001173')},
  {value: 8, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001168')}
]

// 平台订单类型
export const PLATFORMORDERTYPE = [
  {value: 'null', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000095')},
  {value: 'GENERAL', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001278')},
  {value: 'TEMU_S', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001279')},
  {value: 'TEMU_P', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001280')},
  {value: 'ONE_STOP_SERVICE', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001281')},
]

// IOSS 类型
export const IOSSTYPE = [
  {value: 1, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001282')},
  {value: 2, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001283')},
  {value: 3, name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001284')},
]

// 翻译之后的云卖订单状态(订单轨迹)
export const YMSORDERSTATUSLIST = [
  {sort: 0, value: 'null', key: 'null', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000095')},
  {sort: 1, value: 0, key: 'createdTime', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001164')},
  {sort: 2, value: 13, key: 'createdTime', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001285')},
  {sort: 3, value: 1, key: 'printTime', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001286')},
  {sort: 4, value: 2, key: 'pickingFinishTime', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001287')},
  {sort: 5, value: 3, key: 'packingTime', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001288')},
  {sort: 6, value: 4, key: 'supperBoxedTime', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001289')},
  {sort: 7, value: 5, key: 'despatchTime', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001290')},
  {sort: 8, value: 11, key: 'allottedTime', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001291')},
  {sort: 9, value: 6, key: 'allottedTime', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001156')},
  {sort: 10, value: 7, key: 'pickedTime', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001160')},
  {sort: 11, value: 8, key: 'changedLabelTime', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001276')},
  {sort: 12, value: 9, key: 'boxedTime', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001292')},
  {sort: 13, value: 10, key: 'shipedTime', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001293')},
  {sort: 14, value: 12, key: 'cancelledTime', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001163')},
];

export const ATTRIBUTEMULTILINGUALNAME = [
  {key: 'cnName', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000468')},
  {key: 'usName', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001294')},
  {key: 'ukName', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001295')},
  {key: 'auName', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001296')},
  {key: 'euName', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001297')},
  {key: 'frName', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000473')},
  {key: 'deName', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000474')},
  {key: 'esName', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000475')},
  {key: 'nlName', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000476')},
  {key: 'plName', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000477')},
  {key: 'ptName', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000478')},
  {key: 'itName', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000479')},
  {key: 'roName', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000480')},
];

export const ATTRIBUTEVALUEDATA = [
  {key: 'cnValue', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000468')},
  {key: 'usValue', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001294')},
  {key: 'ukValue', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001295')},
  {key: 'auValue', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001296')},
  {key: 'euValue', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001297')},
  {key: 'frValue', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000473')},
  {key: 'deValue', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000474')},
  {key: 'esValue', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000475')},
  {key: 'nlValue', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000476')},
  {key: 'plValue', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000477')},
  {key: 'ptValue', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000478')},
  {key: 'itValue', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000479')},
  {key: 'roValue', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000480')},
];

export const PRODUCTTYPES = [
  {title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000630'), value: 1},
  {title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000632'), value: 2},
  {title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000631'), value: 3},
];

export const VISIBLEAFTERLOGIN = alias1bddc4d174174d47a6dfb0437dace856.t('key1001298');

// 无站点的平台
export const NOSITEPLATFORMLIST = [
  'wish', 'joom', '1688', 'aliexpress', 'fruugo', 'ozon', 'shopify', 'temu', 'tiktok',
  'walmart', 'aliexpressChoice', 'alibaba', 'temu_choice'
];

