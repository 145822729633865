
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';
// 多语种动态日志-- 目前只处理分销商系统涉及的日志
import {setConfigCharacters} from "@/utils/common";

export const dynamicLog = (obj, contentType, system) => {
  // 动态类型值
  let typeObj = {
    LF: alias1bddc4d174174d47a6dfb0437dace856.t('key1001477'),
    FT: alias1bddc4d174174d47a6dfb0437dace856.t('key1001478')
  }

  // 处理地址的动态日志数据问题
  function handleAddress(obj) {
    let newList = [];
    let addressLoglist = [
      {
        paramsKey: ['oldBuyerName', 'newBuyerName'],
        value: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001299')} [ ${obj.oldBuyerName} ] ${alias1bddc4d174174d47a6dfb0437dace856.t('key1000434')} [ ${obj.newBuyerName} ]`
      },
      {
        paramsKey: ['oldBuyerEmail', 'newBuyerEmail'],
        value: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001300')} [ ${obj.oldBuyerEmail} ] ${alias1bddc4d174174d47a6dfb0437dace856.t('key1000434')} [ ${obj.newBuyerEmail} ]`
      },
      {
        paramsKey: ['oldBuyerPhone', 'newBuyerPhone'],
        value: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001301')} [ ${obj.oldBuyerPhone} ] ${alias1bddc4d174174d47a6dfb0437dace856.t('key1000434')} [ ${obj.newBuyerPhone} ]`
      },
      {
        paramsKey: ['oldBuyerAccountId', 'newBuyerAccountId'],
        value: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001302')} [ ${obj.oldBuyerAccountId} ] ${alias1bddc4d174174d47a6dfb0437dace856.t('key1000434')} [ ${obj.newBuyerAccountId} ]`
      },
      {
        paramsKey: ['oldBuyerCountryCode', 'newBuyerCountryCode'],
        value: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001303')} [ ${obj.oldBuyerCountryCode} ] ${alias1bddc4d174174d47a6dfb0437dace856.t('key1000434')} [ ${obj.newBuyerCountryCode} ]`
      },
      {
        paramsKey: ['oldBuyerMobile', 'newBuyerMobile'],
        value: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001304')} [ ${obj.oldBuyerMobile} ] ${alias1bddc4d174174d47a6dfb0437dace856.t('key1000434')} [ ${obj.newBuyerMobile} ]`
      },
      {
        paramsKey: ['oldBuyerAddress1', 'newBuyerAddress1'],
        value: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001305')} [ ${obj.oldBuyerAddress1} ] ${alias1bddc4d174174d47a6dfb0437dace856.t('key1000434')} [ ${obj.newBuyerAddress1} ]`
      },
      {
        paramsKey: ['oldBuyerAddress2', 'newBuyerAddress2'],
        value: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001306')} [ ${obj.oldBuyerAddress2} ] ${alias1bddc4d174174d47a6dfb0437dace856.t('key1000434')} [ ${obj.newBuyerAddress2} ]`
      },
      {
        paramsKey: ['oldBuyerCity', 'newBuyerCity'],
        value: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001307')}  [ ${obj.oldBuyerCity} ] ${alias1bddc4d174174d47a6dfb0437dace856.t('key1000434')} [ ${obj.newBuyerCity} ]`
      },
      {
        paramsKey: ['oldBuyerState', 'newBuyerState'],
        value: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001308')} [ ${obj.oldBuyerState} ] ${alias1bddc4d174174d47a6dfb0437dace856.t('key1000434')} [ ${obj.newBuyerState} ]`
      },
      {
        paramsKey: ['oldBuyerPostalCode', 'newBuyerPostalCode'],
        value: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001309')} [ ${obj.oldBuyerPostalCode} ] ${alias1bddc4d174174d47a6dfb0437dace856.t('key1000434')} [ ${obj.newBuyerPostalCode} ]`
      },
      {
        paramsKey: ['oldBuyerPassportCode', 'newBuyerPassportCode'],
        value: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001310')} [ ${obj.oldBuyerPassportCode} ] ${alias1bddc4d174174d47a6dfb0437dace856.t('key1000434')} [ ${obj.newBuyerPassportCode} ]`
      },
    ];
    if (Object.keys(obj).length > 0) {
      for (let key in obj) {
        addressLoglist.map((item) => {
          if (item.paramsKey.includes(key)) {
            newList.push(item.value)
          }
        })
      }
    }
    newList.unshift(`${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001311')} ${obj.packageCode} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001312')}`);
    let text = [...new Set(newList)].join(';');
    return text;
  }

  // 处理有些动态日志不需要展示在分销商但是需要展示运营
  function handleDynamicLogs(obj, system) {
    let text = '';
    if (contentType === 40) {
      if (system === 'distributor') {
        text = `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001313')}${obj.currency} ${obj.amount}`;
      } else if (system === 'yms') {
        text = `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001314')}${obj.currency} ${obj.amount}`;
      }
    }
    if (contentType === 48) {
      if (system === 'distributor') {
        text = '';
      } else if (system === 'yms') {
        text = `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001315')}${obj.totalPriceCurrency}  ${obj.totalPrice}
         ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001316')}${obj.totalPriceCurrency}  ${obj.distributorStockCost}
         ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001317')}${obj.supplierTotalPriceCurrency}  ${obj.supplierTotalPrice}`
      }
    }
    if (contentType === 52) {
      if (system === 'distributor') {
        text = '';
      } else if (system === 'yms') {
        text = `${obj.subPackageCode} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001318')}`;
      }
    }
    return text;
  }

  /*;是用于页面显示的时候换行展示*/
  let logsObj = {
    1: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001319')}${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001320')}`,
    2: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001321')}`,
    3: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001322')}`,
    4: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001323')}`,
    5: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001324')}`,
    6: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001325')}`,
    7: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001326')}${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001327')} [ ${obj.packageCode} ]`,
    8: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001328')} ${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001327')} [ ${obj.packageCode} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001329')} ${obj.ruleName} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001330')} ${obj.ymsLogisName} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001331')} ${typeObj[obj.dynamicMessage]}`,
    9: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001332')}${obj.platformItemSku}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001333')}${setConfigCharacters}SKU：${obj.ymsSku}`,
    10: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001334')}${obj.platformItemSku}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001335')}${setConfigCharacters}SKU`,
    11: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001336')}${obj.platformItemSku}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001333')}${setConfigCharacters}SKU：${obj.ymsSku}`,
    12: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001337')}${obj.platformItemSku}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001333')}${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001338')}`,
    13: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001337')}${obj.platformItemSku}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001335')}${setConfigCharacters}SKU`,
    14: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001328')} ${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001327')} [ ${obj.packageCode} ]`,
    15: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001327')} [ ${obj.packageCode} ] ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001339')}`,
    16: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001340')}${obj.carrierShippingMethodName} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001341')}${obj.trackingNumber} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001342')}${obj.thirdPartyNo}`,
    17: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001343')}${obj.message}`,
    18: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001344')}${obj.trackingNumber}`,
    19: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001345')}${obj.message}`,
    20: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001327')} [ ${obj.packageCode} ] ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001346')}`,
    21: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001327')} [ ${obj.packageCode} ] ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001347')}`,
    22: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1000884')} ${obj.packageCode} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001348')} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001349')})`,
    23: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001327')} [ ${obj.packageCode} ] ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001346')} ${obj.message}`,
    24: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001311')} ${obj.packageCode} ] ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001350')}`,
    25: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001311')} ${obj.packageCode} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001351')} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001352')} [ ${obj.oldCarrierContent} ] ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001353')} [ ${obj.newCarrierContent} ]`,
    26: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001311')} ${obj.packageCode} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001354')}`,
    27: handleAddress(obj),
    28: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001355')}`,
    29: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001356')}`,
    30: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1000500')}`,
    31: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1000499')}`,
    32: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001357')}`,
    33: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001358')}`,
    34: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001359')}${obj.iossName}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001360')}${obj.iossCode}`,
    35: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001361')}${obj.iossName}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001360')}${obj.iossCode}`,
    36: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001362')}${obj.iossName}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001363')}${obj.iossCode}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001364')}${obj.platformIds}`,
    37: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001362')}${obj.iossName}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001363')}${obj.iossCode}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001365')}${obj.shopIds}`,
    38: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001366')}${obj.iossName}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001363')}${obj.iossCode}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001364')}${obj.platformIds}`,
    39: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001366')}${obj.iossName}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001363')}${obj.iossCode}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001365')}${obj.shopIds}`,
    40: handleDynamicLogs(obj, system),
    41: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001367')}${obj.currency} ${obj.amount}`,
    42: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001368')}${obj.currency} ${obj.amount}`,
    43: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001369')}`,
    44: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001370')}`,
    45: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001371')}`,
    46: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001372')} ${obj.amount}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001373')}${obj.currency}`,
    47: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001374')}`,
    48: handleDynamicLogs(obj, system),
    49: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001375')}`,
    50: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001376')}${obj.estimateArrivalOfGoodsTime}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001377')}${obj.ymsSkuContent}`,
    51: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001378')}${obj.ymsSkuContent}`,
    52: handleDynamicLogs(obj, system),
    53: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001379')}`,
    54: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001380')}`,
    55: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1000499')}${obj.cnValue}${alias1bddc4d174174d47a6dfb0437dace856.t('key1000217')}`,
    56: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1000500')}${obj.cnValue}${alias1bddc4d174174d47a6dfb0437dace856.t('key1000217')}`,
    57: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1000981')}${obj.cnValue}${alias1bddc4d174174d47a6dfb0437dace856.t('key1000217')}`,
    58: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1000226')}${obj.content}`,
    59: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001381')}`,
    60: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001382')}`,
    61: `${obj.subPackageCode}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001318')}`,
    62: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001383')}`,
    63: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001384')}`,
    64: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001385')}${obj.carrierShippingMethodName}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001386')}${obj.trackingNumber}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001387')}${obj.thirdPartyNo}`,
    65: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001388')}`,
    66: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001389')}`,
    67: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001390')}`,
    68: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001391')}`,
    69: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001392')}`,
    70: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001393')}`,
    71: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001394')}${obj.rate}`,
    72: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001395')}${obj.rate}`,
    73: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001396')}`,
    74: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001397')}`,
    75: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001398')}`,
    76: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001399')}${obj.ymsPickingOrderCode}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001400')}`,
    77: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001167')}`,
    78: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001401')}`,
    79: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001402')}`,
    80: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001403')}${obj.code}】`,
    81: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001404')}`,
    82: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001405')}`,
    83: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001406')}`,
    84: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001407')}`,
    85: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001408')}`,
    86: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001409')}`,
    87: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001410')}`,
    88: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1000654')}`,
    89: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001411')}${obj.describe}`,
    90: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001412')}  ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001413')} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001414')}${obj.describe}`,
    91: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001415')} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001413')} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1000654')}`,
    92: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001412')} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001413')} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1000654')}`,
    93: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001415')} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001413')} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001416')} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001411')}${obj.describe}`,
    94: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001417')} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001418')}${obj.subsidyAmount}${obj.subsidyAmountCurrency}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001419')}${obj.modifySubsidyMoney}${obj.subsidyAmountCurrency}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001420')}${obj.describe}`,
    95: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001410')}`,
    96: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001421')}`,
    97: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001422')}`,
    98: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001423')}`,
    99: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001424')}`,
    100: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001425')}`,
    101: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001426')}`,
    102: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001427')}${obj.auditRemarks}`,
    103: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001428')}`,
    104: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001429')}`,
    105: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001430')}`,
    106: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001431')}${obj.carrierName}：${obj.carrierShippingMethodName}`,
    107: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001432')}`,
    108: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001165')}`,
    109: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001433')}${obj.packageCode}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001434')}${obj.warehouseName}`,
    110: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001436')}${obj.invalidReason || alias1bddc4d174174d47a6dfb0437dace856.t('key1001435')}`,
    111: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001437')}${obj.oldName}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001438')}${obj.newName}]`,
    112: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1000884')}${obj.packageCode}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001328')}${obj.carrierName}_${obj.carrierShippingMethodName}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001439')}(${obj.ruleName})`,
    113: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1000884')}${obj.packageCode}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001328')}${obj.carrierName}_${obj.carrierShippingMethodName}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001440')}`,
    114: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1000884')}${obj.packageCode}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001328')}${obj.carrierName}_${obj.carrierShippingMethodName}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001441')}`,
    115: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001442')}`,
    116: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001443')}`,
    117: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001444')}`,
    118: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001445')}`,
    119: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001446')} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001447')}${obj.trackingNumber})`,
    120: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001443')} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001448')}${obj.carrierErrorMsg})`,
    121: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001449')}`,
    122: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001450')}`,
    123: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001451')}`,
    124: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001452')}${obj.businessId}】`,
    125: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001453')}`,
    126: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001454')}`,
    127: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001455')}`,
    128: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001456')}`,
    129: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001457')}`,
    130: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001458')}`,
    131: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001459')}`,
    132: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001460')}`,
    133: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001461')}`,
    134: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001462')}${obj.invalidReason}`,
    135: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001463')}`,
    136: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001464')}`,
    137: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001465')}`,
    138: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001466')}${obj.renewalPrice}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001467')}${obj.renewalMonth} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1001468')}`,
    139: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001469')}`,
    140: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001470')}${obj.auditRemarks}`,
    141: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001471')}${obj.content}`,
    142: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001472')}`,
    143: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001473')}${obj.packageTagName}`,
    144: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001474')}${obj.packageTagName}`,
    145: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1001475')}`,
    146: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001476')}${obj.content}`
  };
  return logsObj[contentType];

}
